import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import HeaderM from 'components/HeaderM';
import ScaleCarouselM from 'components/ScaleCarouselM';
import AccCarouselM from 'components/AccCarouselM';
import StepCarouselM from 'components/StepCarouselM';
import CardListM from 'components/CardListM';
import TeachEnvM from 'components/TeachEnvM/TeachEnvMWX';
import FormBannerM from 'components/FormBannerM/FormBannerMWX';
import FixedBtnM from 'components/FixedBtnM';
import CopyRightM from 'components/CopyRightM';
import useCR from 'utils/useCR';
import useSource from 'utils/useSource';
import track from 'utils/track';
import { getParams } from 'utils/utils';

export default ({ location: { search } }) => {
  // 是否展示版权
  const cr = useCR(search);
  const source = useSource(search, '8');
  const [classroomId, setClassroomId] = useState(getParams(search).classroomId);

  useEffect(() => {
    track().pv('【指教音乐官网】成人钢琴——页面访问');
  }, []);
  if (cr) {
    return (
      <iframe
        style={{ border: 'none' }}
        width={window.innerWidth}
        height={window.innerHeight}
        src="https://www.finger66.com/activity/teacher-join/#/?source=410000"
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Finger-成人零基础钢琴</title>
      </Helmet>
      <HeaderM
        menus={[
          {
            id: '',
            title: '首页',
          },
          {
            id: 'scale-carousel',
            title: '演奏到创作',
          },
          {
            id: 'acc-carousel',
            title: '课程体系',
          },
          {
            id: 'step-carousel',
            title: '上课流程',
          },
          {
            id: 'card-list',
            title: '常见问题',
          },
          {
            id: 'tech-env',
            title: '杭州连锁校区',
          },
          {
            id: 'book-form',
            title: '免费预约',
            active: true,
          },
        ].filter(Boolean)}
        onPhoneClick={() => track().pv('【指教音乐官网】成人钢琴——电话咨询')}
      />
      {/* form 和 banner */}
      <FormBannerM
        landingImage={`https://front.finger66.com/adultBanner1.png`}
        discountTitle="399元的成人钢琴课程"
        instrument="piano"
        customer="adult"
        source={source}
        classroomId={classroomId}
        onSuccess={() => {
          alert('预约成功！');
          track().pv('【指教音乐官网】成人钢琴——成功提交信息');
          window.location.reload();
        }}
      />
      {/* 从演奏到创作 */}
      <ScaleCarouselM
        style={{ marginTop: '-1px' }}
        title="从演奏到创作"
        description="满足各个阶段水平的要求"
        list={[
          {
            src: require('images/piano/adult/scale-1.jpg'),
            style: { maxWidth: 480 },
            title: '零基础小班课',
            desc:
              '从零开始学钢琴，学习五线谱、钢琴琴键知识与基础演奏能力。课程搭配数十首经典作品，让成人快速掌握一定水平的视奏能力与基础乐理知识。',
            video: 'http://media.finger66.com/zhijiao/zhijiao-ljc-mzdhl.mp4',
          },
          {
            src: require('images/piano/adult/scale-2.jpg'),
            style: { maxWidth: 480 },
            title: '进阶精英课',
            desc:
              '在基础演奏技巧之上引导学员学习特定的演奏方向，学员会学习即兴伴奏、流行弹唱、四手联弹等难度较高的内容，。同时掌握更复杂的演奏技巧，综合能力达到较高水平。',
            video: 'http://media.finger66.com/zhijiao/zhijiao-jpk-lvse.mp4',
          },
          {
            src: require('images/piano/adult/scale-3.jpg'),
            style: { maxWidth: 480 },
            title: '一对一定制课',
            desc:
              '适合有一定钢琴基础的学员进行定制化学习。老师会根据学员的学习需求单独安排课程内容。',
            video: 'http://media.finger66.com/zhijiao/zhijiao-despacito.mp4',
          },
        ]}
      />
      {/* 课程体系 */}
      <AccCarouselM
        style={{ marginTop: '-2px' }}
        title="课程体系"
        description="美式钢琴教学体系 专业团队匠心研制"
        list={[
          {
            style: { maxWidh: 480 },
            src: require('images/piano/adult/acc-1.jpg'),
            title: '01/零基础',
            steps: [
              '基本乐理知识与双手演奏能力',
              '音阶、琶音、和弦伴奏',
              '运用各类常见伴奏音型',
              '多种风格的大型乐曲演奏',
            ],
          },
          {
            style: { maxWidth: 480 },
            src: require('images/piano/adult/acc-2.jpg'),
            title: '02/精品课',
            type: 'index',
            steps: [
              '流行伴奏编排、调性转换',
              '通晓即兴伴奏，轻松演奏',
              '自弹自唱，一人制霸舞台',
              '四手联弹，体验合作趣味',
              '自主创作，弹奏独家乐曲',
            ],
          },
        ]}
      />
      {/* 上课流程 */}
      <StepCarouselM
        style={{ marginTop: '-3px' }}
        title="上课流程"
        description="互联网时代下的音乐教育模式"
        list={[
          {
            src: require('images/piano/adult/step-1.jpg'),
            itemStyle: { maxWidth: 480 },
            step: '01',
            title: '【课前】掌上课程表 随时查询课程信息',
            content: [
              '下载Finger指教APP端 随时查询/预约上课时间',
              '有效期限内可无限重听小班课，直到学会为止',
            ],
          },
          {
            src: require('images/piano/adult/step-2.jpg'),
            itemStyle: { maxWidth: 480 },
            step: '02',
            title: '【课中】零基础课、精品课、一对一定制课',
            content: [
              '独家研发3类课程，适合不同阶段的成人学员',
              '分阶段学习，轻松掌握即兴演奏与音乐创作能力',
            ],
          },
          {
            src: require('images/piano/adult/step-3.jpg'),
            itemStyle: { maxWdth: 480 },
            step: '03',
            title: '【课后】附赠琴房练习，学员可到店免费练习',
            content: [
              '成为正式学员，即可享受免费练琴福利',
              '下载APP，自主预约琴房使用时间',
            ],
          },
        ]}
      />
      {/* 你的担心，其实都不是问题 */}
      <CardListM
        style={{ marginTop: '-4px' }}
        title="你的担心，其实都不是问题"
        description="更适合成人的学习模式与教学特色"
        list={[
          {
            itemStyle: { maxWidth: 480 },
            src: require('images/piano/adult/card-1.jpg'),
            title: '现在学琴会不会太晚？',
            content: [
              '年龄并不是钢琴学习的硬性门槛。',
              '成人的理解能力更强，能通过作品更快地掌握知识。',
              '在肢体协调能力上，成人的脑部已发育完全，也更具优势。',
            ],
          },
          {
            itemStyle: { maxWidth: 480 },
            src: require('images/piano/adult/card-2.jpg'),
            title: '没有基础，害怕跟不上进度？',
            content: [
              '小班教学能够保证课堂教学质量，老师会手把手进行教学。',
              '同班同学都是学习阶段相近的，课堂内容不会出现过快的情况。',
              '听不懂的课程在有效期限内可无限重听，直到学会为止。',
            ],
          },
          {
            itemStyle: { maxWidth: 480 },
            src: require('images/piano/adult/card-3.jpg'),
            title: '工作忙，没有固定的上课时间？',
            content: [
              '短期出差、外出游玩时可选择冻结课时，回来继续上课，不浪费课程有效期',
              '工作日晚间时段、双休日、节假日课程密集，满足上班族的通勤规则',
            ],
          },
          {
            itemStyle: { maxWidth: 480 },
            src: require('images/piano/adult/card-4.jpg'),
            title: '家里没琴，如何练习？',
            content: [
              '成为正式学员，即可享受免费练琴福利',
              '会员专享购琴低价，品牌电子钢琴任你挑选',
              '日本进口钢琴租借，日租费用低至9.9元',
            ],
          },
        ]}
      />

      {/* 教学环境 */}
      <TeachEnvM
        title={'杭州连锁校区'}
        description="专业设计＆施工团队，打造杭城环境一流的音乐教育机构"
        style={{ marginTop: '-6px', paddingBottom: '40px' }}
        pictures={[
          {
            thumbnail: {
              src: require('images/piano/adult/env1.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env1.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env2.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env2.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env3.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env3.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env4.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env4.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env5.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env5.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env6.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env6.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env7.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env7.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env8.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env8.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env9.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env9.jpg'),
              style: { width: 1980 },
            },
          },
        ]}
      />
      {cr && <CopyRightM />}
      <FixedBtnM
        showCopyRight={cr}
        onClick={() => track().pv('【指教音乐官网】成人钢琴——底部免费预约')}
      />
    </>
  );
};
